var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      staticClass: "govuk-select",
      class: _vm.computedClasses,
      attrs: {
        id: _vm.id,
        name: _vm.name,
        "aria-describedby": _vm.ariaDescribedBy
      },
      domProps: { value: _vm.value },
      on: {
        change: function($event) {
          return _vm.$emit("input", $event.target.value)
        }
      }
    },
    [
      _vm._t("default"),
      _vm.options
        ? _vm._l(_vm.options, function(option, key) {
            return _c(
              "option",
              {
                key: key,
                attrs: { disabled: option.disabled },
                domProps: { value: option.value }
              },
              [_vm._v("\n      " + _vm._s(option.text) + "\n    ")]
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }